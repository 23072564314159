import css from "../../css/footer/footer.module.css";
import copyrightCss from "../../css/footer/copyright.module.css";

import TrendingSearches from "./TrendingSearches";
import BrowseRealEstate from "./BrowseRealEstate";
import CitiesNearby from "./CitiesNearby";
import ToolsAndInformation from "./ToolsAndInformation";
import Copyright from "./Copyright";
import IpContext from "../context/Ip";

import React, { useContext } from "react";

const Footer = () => {
    const ip = useContext(IpContext);

    return (
        <div className={css.footer}>
            <div className={css.menu}>
                <div className={css.menuWrapper}>
                    <TrendingSearches />
                    <BrowseRealEstate />
                    <CitiesNearby ip={ip} />
                    <ToolsAndInformation />
                </div>
            </div>
            <div className={css.copyright}>
                <div className={css.copyrightWrapper}>
                    <Copyright theme={copyrightCss} />
                </div>
            </div>
        </div>
    );
};

export default Footer;
