import css from "../../css/footer/footer.module.css";

import MoreLess from "./MoreLess";

import React, { useState } from "react";

const MAX_DISPLAYED = 7;

const SEARCHES = [
    {
        label: "San Diego Homes For Sale",
        url: "/homesearch/for_sale/CA/San_Diego",
    },
    { label: "Del Mar Homes For Sale", url: "/homesearch/for_sale/CA/Del_Mar" },
    { label: "La Mesa Homes For Sale", url: "/homesearch/for_sale/CA/La_Mesa" },
    {
        label: "Carlsbad Homes For Sale",
        url: "/homesearch/for_sale/CA/Carlsbad",
    },
    {
        label: "Fallbrook Homes For Sale",
        url: "/homesearch/for_sale/CA/Fallbrook",
    },
    {
        label: "Escondido Homes For Sale",
        url: "/homesearch/for_sale/CA/Escondido",
    },
    {
        label: "Solana Beach Homes For Sale",
        url: "/homesearch/for_sale/CA/Solana_Beach",
    },
    { label: "Alpine Homes For Sale", url: "/homesearch/for_sale/CA/Alpine" },
    {
        label: "Spring Valley Homes For Sale",
        url: "/homesearch/for_sale/CA/Spring_Valley",
    },
    {
        label: "Oceanside Homes For Sale",
        url: "/homesearch/for_sale/CA/Oceanside",
    },
];

const TrendingSearches = () => {
    const [collapsed, setCollapsed] = useState(true);

    const toggle = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div className={css.menuList}>
            <div className={css.menuTitle}>Trending Searches</div>
            <div>
                {SEARCHES.map(
                    (search, index) =>
                        index < MAX_DISPLAYED && (
                            <a
                                className={css.menuItem}
                                key={search.url}
                                href={search.url}
                            >
                                {search.label}
                            </a>
                        )
                )}

                {SEARCHES && SEARCHES.length > MAX_DISPLAYED && (
                    <>
                        <div
                            className={
                                collapsed ? css.contentCollapsed : css.content
                            }
                        >
                            {SEARCHES.map(
                                (search, index) =>
                                    index >= MAX_DISPLAYED && (
                                        <a
                                            className={css.menuItem}
                                            key={search.url}
                                            href={search.url}
                                        >
                                            {search.label}
                                        </a>
                                    )
                            )}
                        </div>
                        <MoreLess collapsed={collapsed} onClick={toggle} />
                    </>
                )}
            </div>
        </div>
    );
};

export default TrendingSearches;
