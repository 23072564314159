import css from "../../css/footer/footer.module.css";

import ArrowDropUpIcon from "mdi-react/ArrowDropUpIcon";
import ArrowDropDownIcon from "mdi-react/ArrowDropDownIcon";

import React from "react";
import PropTypes from "prop-types";

const MoreLess = (props) => {
    const { collapsed, onClick } = props;

    return (
        <>
            {collapsed && (
                <span className={css.more} onClick={onClick}>
                    More <ArrowDropDownIcon className={css.moreIcon} />
                </span>
            )}
            {!collapsed && (
                <span className={css.more} onClick={onClick}>
                    Less <ArrowDropUpIcon className={css.moreIcon} />
                </span>
            )}
        </>
    );
};

MoreLess.propTypes = {
    collapsed: PropTypes.bool.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default MoreLess;
