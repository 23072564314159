import axiosResiInstance from "../http/axiosResi";
import {
    toastPerType,
    NOTIFICATION_SUCCESS,
} from "../components/ToastNotification";

const DEFAULT_CITY = {
    id: "CA-LOS-ANGELES",
    name: "Los Angeles",
    url: "CA/Los_Angeles",
    state: {
        id: "CA",
        abbr: "CA",
        url: "CA",
        name: "California",
    },
    label: "Los Angeles, CA",
};

const STATES = [
    {
        name: "Alabama",
        abbr: "AL",
        url: "AL",
        id: "AL",
    },
    {
        name: "Alaska",
        abbr: "AK",
        url: "AK",
        id: "AK",
    },
    {
        name: "Arizona",
        abbr: "AZ",
        url: "AZ",
        id: "AZ",
    },
    {
        name: "Arkansas",
        abbr: "AR",
        url: "AR",
        id: "AR",
    },
    {
        name: "California",
        abbr: "CA",
        url: "CA",
        id: "CA",
    },
    {
        name: "Colorado",
        abbr: "CO",
        url: "CO",
        id: "CO",
    },
    {
        name: "Connecticut",
        abbr: "CT",
        url: "CT",
        id: "CT",
    },
    {
        name: "Delaware",
        abbr: "DE",
        url: "DE",
        id: "DE",
    },
    {
        name: "District of Columbia",
        abbr: "DC",
        url: "DC",
        id: "DC",
    },
    {
        name: "Florida",
        abbr: "FL",
        url: "FL",
        id: "FL",
    },
    {
        name: "Georgia",
        abbr: "GA",
        url: "GA",
        id: "GA",
    },
    {
        name: "Hawaii",
        abbr: "HI",
        url: "HI",
        id: "HI",
    },
    {
        name: "Idaho",
        abbr: "ID",
        url: "ID",
        id: "ID",
    },
    {
        name: "Illinois",
        abbr: "IL",
        url: "IL",
        id: "IL",
    },
    {
        name: "Indiana",
        abbr: "IN",
        url: "IN",
        id: "IN",
    },
    {
        name: "Iowa",
        abbr: "IA",
        url: "IA",
        id: "IA",
    },
    {
        name: "Kansas",
        abbr: "KS",
        url: "KS",
        id: "KS",
    },
    {
        name: "Kentucky",
        abbr: "KY",
        url: "KY",
        id: "KY",
    },
    {
        name: "Louisiana",
        abbr: "LA",
        url: "LA",
        id: "LA",
    },
    {
        name: "Maine",
        abbr: "ME",
        url: "ME",
        id: "ME",
    },
    {
        name: "Maryland",
        abbr: "MD",
        url: "MD",
        id: "MD",
    },
    {
        name: "Massachusetts",
        abbr: "MA",
        url: "MA",
        id: "MA",
    },
    {
        name: "Michigan",
        abbr: "MI",
        url: "MI",
        id: "MI",
    },
    {
        name: "Minnesota",
        abbr: "MN",
        url: "MN",
        id: "MN",
    },
    {
        name: "Mississippi",
        abbr: "MS",
        url: "MS",
        id: "MS",
    },
    {
        name: "Missouri",
        abbr: "MO",
        url: "MO",
        id: "MO",
    },
    {
        name: "Montana",
        abbr: "MT",
        url: "MT",
        id: "MT",
    },
    {
        name: "Nebraska",
        abbr: "NE",
        url: "NE",
        id: "NE",
    },
    {
        name: "Nevada",
        abbr: "NV",
        url: "NV",
        id: "NV",
    },
    {
        name: "New Hampshire",
        abbr: "NH",
        url: "NH",
        id: "NH",
    },
    {
        name: "New Jersey",
        abbr: "NJ",
        url: "NJ",
        id: "NJ",
    },
    {
        name: "New Mexico",
        abbr: "NM",
        url: "NM",
        id: "NM",
    },
    {
        name: "New York",
        abbr: "NY",
        url: "NY",
        id: "NY",
    },
    {
        name: "North Carolina",
        abbr: "NC",
        url: "NC",
        id: "NC",
    },
    {
        name: "North Dakota",
        abbr: "ND",
        url: "ND",
        id: "ND",
    },
    {
        name: "Ohio",
        abbr: "OH",
        url: "OH",
        id: "OH",
    },
    {
        name: "Oklahoma",
        abbr: "OK",
        url: "OK",
        id: "OK",
    },
    {
        name: "Oregon",
        abbr: "OR",
        url: "OR",
        id: "OR",
    },
    {
        name: "Pennsylvania",
        abbr: "PA",
        url: "PA",
        id: "PA",
    },
    {
        name: "Rhode Island",
        abbr: "RI",
        url: "RI",
        id: "RI",
    },
    {
        name: "South Carolina",
        abbr: "SC",
        url: "SC",
        id: "SC",
    },
    {
        name: "South Dakota",
        abbr: "SD",
        url: "SD",
        id: "SD",
    },
    {
        name: "Tennessee",
        abbr: "TN",
        url: "TN",
        id: "TN",
    },
    {
        name: "Texas",
        abbr: "TX",
        url: "TX",
        id: "TX",
    },
    {
        name: "Utah",
        abbr: "UT",
        url: "UT",
        id: "UT",
    },
    {
        name: "Vermont",
        abbr: "VT",
        url: "VT",
        id: "VT",
    },
    {
        name: "Virginia",
        abbr: "VA",
        url: "VA",
        id: "VA",
    },
    {
        name: "Washington",
        abbr: "WA",
        url: "WA",
        id: "WA",
    },
    {
        name: "West Virginia",
        abbr: "WV",
        url: "WV",
        id: "WV",
    },
    {
        name: "Wisconsin",
        abbr: "WI",
        url: "WI",
        id: "WI",
    },
    {
        name: "Wyoming",
        abbr: "WY",
        url: "WY",
        id: "WY",
    },
];
export const isLocalStorageSupported = () => {
    return typeof Storage !== "undefined";
};

export const fetchCities = (city) => {
    return axiosResiInstance.get("/cities", {
        params: {
            nearby_city: city.id,
            limit: 50,
        },
    });
};

export const fetchGepIp = (ip = null) => {
    const params = ip ? { ip } : {};

    return axiosResiInstance.get("/geoip", { params });
};

export const getStates = () => {
    return STATES;
};

export const getCities = async (ip) => {
    if (!isLocalStorageSupported()) {
        return {
            data: null,
        };
    }

    let cities = localStorage.getItem("cities");

    if (cities) {
        const cachedCities = JSON.parse(cities);

        if (!ip || cachedCities.ip === ip) {
            return cachedCities.data;
        }
    }

    // FETCH NEW CITIES
    let ipCity = null;

    if (ip) {
        // geoIp City
        ipCity = await fetchGepIp(ip);
        ipCity = ipCity.data ? ipCity.data.city : null;
    }

    if (!ipCity) {
        ipCity = DEFAULT_CITY;
    }

    cities = await fetchCities(ipCity);
    cities = cities.data.data;

    localStorage.setItem(
        "cities",
        JSON.stringify({
            data: cities,
            ip,
        })
    );

    return cities;
};

export const cacheToastMessage = (message, type = NOTIFICATION_SUCCESS) => {
    if (!isLocalStorageSupported()) {
        return;
    }

    localStorage.setItem(
        "toast-notification",
        JSON.stringify({
            message,
            type,
        })
    );
};

export const toastMessage = () => {
    if (!isLocalStorageSupported()) {
        return null;
    }

    let toastNotification = localStorage.getItem("toast-notification");

    localStorage.removeItem("toast-notification");

    if (toastNotification) {
        toastNotification = JSON.parse(toastNotification);

        toastPerType(toastNotification.message, toastNotification.type);
    }
};
