import css from "../../css/footer/footer.module.css";

import MoreLess from "./MoreLess";

import React, { useEffect, useState } from "react";
import { getStates } from "../../service/cache";

const MAX_DISPLAYED = 7;

const BrowseRealEstate = (props) => {
    const [states, setStates] = useState(null);
    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        setStates(getStates());
    }, []);

    const toggle = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div className={css.menuList}>
            <div className={css.menuTitle}>Browse Real Estate</div>
            <div>
                {states &&
                    states.map(
                        (state, index) =>
                            index < MAX_DISPLAYED && (
                                <a
                                    className={css.menuItem}
                                    key={state.id}
                                    href={`/sitemap/${state.url}`}
                                >
                                    {state.name} Real Estate
                                </a>
                            )
                    )}

                {states && states.length > MAX_DISPLAYED && (
                    <>
                        <div
                            className={
                                collapsed ? css.contentCollapsed : css.content
                            }
                        >
                            {states.map(
                                (state, index) =>
                                    index >= MAX_DISPLAYED && (
                                        <a
                                            className={css.menuItem}
                                            key={state.id}
                                            href={`/sitemap/${state.url}`}
                                        >
                                            {state.name} Real Estate
                                        </a>
                                    )
                            )}
                        </div>
                        <MoreLess collapsed={collapsed} onClick={toggle} />
                    </>
                )}
            </div>
        </div>
    );
};

export default BrowseRealEstate;
