import css from "../../css/footer/footer.module.css";

import MoreLess from "./MoreLess";

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getCities } from "../../service/cache";

const MAX_DISPLAYED = 7;

const CitiesNearby = (props) => {
    const [cities, setCities] = useState(null);
    const [collapsed, setCollapsed] = useState(true);

    useEffect(() => {
        const fetchCities = async (ip) => {
            const cities = await getCities(ip);

            setCities(cities);
        };

        fetchCities(props.ip);
    }, []);

    const toggle = () => {
        setCollapsed(!collapsed);
    };

    return (
        <div className={css.menuList}>
            <div className={css.menuTitle}>Cities Nearby</div>
            <div>
                {cities &&
                    cities.map(
                        (city, index) =>
                            index < MAX_DISPLAYED && (
                                <a
                                    className={css.menuItem}
                                    key={city.id}
                                    href={`/homesearch/for_sale/${city.url}`}
                                >
                                    {city.name_with_state} Homes
                                </a>
                            )
                    )}

                {cities && cities.length > MAX_DISPLAYED && (
                    <>
                        <div
                            className={
                                collapsed ? css.contentCollapsed : css.content
                            }
                        >
                            {cities.map(
                                (city, index) =>
                                    index >= MAX_DISPLAYED && (
                                        <a
                                            className={css.menuItem}
                                            key={city.id}
                                            href={`/homesearch/for_sale/${city.url}`}
                                        >
                                            {city.name_with_state} Homes
                                        </a>
                                    )
                            )}
                        </div>
                        <MoreLess collapsed={collapsed} onClick={toggle} />
                    </>
                )}
            </div>
        </div>
    );
};

CitiesNearby.propTypes = {
    ip: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
};

export default CitiesNearby;
